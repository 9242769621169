import React, { useState, useEffect } from 'react';
import { DataBrowser } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';

const Fortress = window.cccisd.fortress;

const ExportData = props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div className="container-fluid">
            <DataBrowser key={Fortress.user.acting.id} projectId={3} />
        </div>
    );
};

export default ExportData;
